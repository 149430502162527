import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/introducing-cache-policies",
  "date": "15th February 2023",
  "title": "The sky is the limit with CloudFront Cache Policies",
  "summary": "Introducing AWS Managed Services CloudFront Cache Policies to the Skpr platform.",
  "author": "Nick Schuch",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "Cache Policies"
  }, {
    "name": "AWS"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "/images/posts/cloudfront-cache-policies.jpg",
        "alt": "Image of clouds"
      }}></img></p>
    <h2>{`Why we use Cache Policies`}</h2>
    <p>{`Caching is vital! As your cache hit percentage rises, the more effectively resources are allocated towards your application’s availability. `}</p>
    <p>{`Discover the key role that CDN caching plays in `}<a parentName="p" {...{
        "href": "https://www.skpr.com.au/blog/surviving-unprecedented-traffic-spikes"
      }}>{`scaling on demand`}</a>{` in this video.`}</p>
    <p>{`At the caching layer, Skpr uses CloudFront Cache Policies to ensure your applications are operating at peak performance.`}</p>
    <p>{`Our hosting platform provisions a CloudFront distribution for each environment. With Cache Policies, we can automatically and seamlessly keep all Skpr-managed projects up to date and synchronised between environments.`}</p>
    <h2>{`Configuration`}</h2>
    <p>{`Cache policies are configured on all environments by default, with the appropriate policy chosen based on the deployment type, for example, Drupal, Basic or PHP.`}</p>
    <p>{`Development teams can override the cache policy using the `}<a parentName="p" {...{
        "href": "https://docs.skpr.io/using-skpr/config/cache"
      }}>{`routing configuration`}</a>{`.`}</p>
    <p>{`The Skpr Platform team can also provision custom cache policies to suit your requirements.`}</p>
    <h2>{`Moving forward with Skpr`}</h2>
    <p>{`We’re constantly improving Skpr’s CDN solution for our customers. Cache Policies are just one example of the way in which we embrace and keep up with the latest changes in AWS Managed Services.`}</p>
    <p>{`You can also see our latest platform announcements by checking out the `}<a parentName="p" {...{
        "href": "https://docs.skpr.io/roadmap/"
      }}>{`Skpr Roadmap`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      